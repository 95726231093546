<template>
  <v-tabs
    v-bind="$attrs"
    v-model="internalActiveTab"
    :class="[b(), $attrs.class]"
    :grow="$viewport.isMobile"
  >
    <v-tab
      v-for="tab in tabs"
      :key="tab.name"
      :value="tab.name"
      :class="b('tab-item', { active: activeTab?.path === tab.path })"
      :disabled="tab.disabled"
      @click="onTabClicked(tab.name)"
    >
      {{ tab.meta.title }}
    </v-tab>
  </v-tabs>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { RouteRecordRaw } from 'vue-router';

  // type Setup = {};
  type Data = {
    internalActiveTab: string;
  };

  export type TabItem = {
    name: string;
    path: string;
    disabled?: boolean;
    meta: { title: string };
  };

  export default defineComponent({
    name: 'c-tab-navigation',
    inheritAttrs: false,

    // components: {},

    props: {
      /**
       * Navigation items
       */
      tabs: {
        type: Array as PropType<TabItem[]>,
        required: true,
      },
    },
    // emits: [],

    // setup(): Setup {
    //   return {
    //   };
    // },
    data(): Data {
      return {
        internalActiveTab: '',
      };
    },

    computed: {
      activeTab(): Partial<RouteRecordRaw> | undefined {
        // Find the active parent route
        const match = this.tabs.find((tab) => this.$route.matched.some((route) => route.name === tab.name));

        return match || this.tabs[0];
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      this.internalActiveTab = (this.activeTab?.name as string) || '';
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      onTabClicked(name: string) {
        if (this.$route.matched.some((route) => route.name === name)) {
          // We already are on the requested route
          return;
        }

        this.$router.push({
          name,
        });
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:map';
  @use 'vuetify/settings' as vuetify;
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-tab-navigation {
    $this: &;

    &__tab-item {
      background: map-get(vuetify.$grey, 'lighten-2') !important; // stylelint-disable-line
      font-family: variables.$font-family-default-condensed !important; // stylelint-disable-line

      .v-btn,
      .v-btn__content{
        letter-spacing: 0.8px; // stylelint-disable-line
      }

      &--active {
        background: rgba(map-get(vuetify.$grey, 'lighten-1'), 0.5) !important; // stylelint-disable-line
        font-weight: variables.$font-weight--bold !important; // stylelint-disable-line
      }
    }
  }
</style>
