<template>
  <div :class="b()">
    <div :class="b('content')">
      <div :class="b('inner')">
        <div :class="b('menu')">
          <c-tab-navigation
            :class="b('tabs')"
            :tabs="mainNavigation"
          />
          <c-team-season-selectors :class="b('selects')" />
          <c-stats-selectors
            v-if="showStatsSelect"
            :class="b('stats-select')"
          />
        </div>
        <router-view v-slot="{ Component }">
          <transition
            name="fade"
            mode="out-in"
          >
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { PLAYOFF, SCHEDULE, STANDINGS, STATS_BASE } from '@/setup/routes-constants';
  import useSessionStore from '@/stores/session';
  import useTeamsStore from '@/stores/teams';
  import cStatsSelectors from '@/components/c-stats-selectors.vue';
  import cTabNavigation, { TabItem } from '@/components/c-tab-navigation.vue';
  import cTeamSeasonSelectors from '@/components/c-team-season-selectors.vue';

  type Setup = {
    sessionStore: ReturnType<typeof useSessionStore>;
    teamsStore: ReturnType<typeof useTeamsStore>;
  };
  type Data = {
    mainNavigation: TabItem[];
  };

  export default defineComponent({
    name: 'l-gamecenter',
    components: {
      cStatsSelectors,
      cTabNavigation,
      cTeamSeasonSelectors,
    },

    // components: {},

    // props: {},

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
        teamsStore: useTeamsStore(),
      };
    },
    data(): Data {
      return {
        mainNavigation: [{ ...SCHEDULE }, { ...STANDINGS }, { ...PLAYOFF, disabled: true }, { ...STATS_BASE }],
      };
    },

    computed: {
      showStatsSelect(): boolean {
        return String(this.$route.name).includes('stats.');
      },
    },
    watch: {
      // Poor hockey data api forces us to fetch the team names in order to use them across the app.
      'sessionStore.activeSeason': {
        handler() {
          this.teamsStore.apiFetchTeams(this.sessionStore.activeSeason.divisionId);

          const playoffMenuItem = this.mainNavigation.find((item) => item.name === PLAYOFF.name);

          if (playoffMenuItem) {
            playoffMenuItem.disabled = this.sessionStore.activeSeason.playoffId === null;
          }
        },
        immediate: true,
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';
  @use 'vuetify/settings' as vuetify;

  .l-gamecenter {
    @include mixins.pageTransition();

    display: flex;
    flex-direction: column;
    padding-bottom: 8rem;

    &__content {
      flex: 1 0 auto;
    }

    &__inner {
      position: relative;
      max-width: #{map-get(variables.$breakpoints, xl) - 20px};
      margin: 0 auto;
      background: variables.$color-grayscale--1000;
    }

    &__menu {
      display: grid;
      grid-template-areas:
        'selects'
        'tabs'
        'stats';
      grid-template-columns: 100%;
      background: map-get(vuetify.$grey, 'lighten-3');
      padding: 20px 8px;
      gap: 20px;
      margin-bottom: 60px;

      @include mixins.media(sm) {
        grid-template-areas:
          'tabs selects'
          'stats stats';
        grid-template-columns: auto 0.5fr;
        padding: 20px 10px;
      }
    }

    &__tabs {
      grid-area: tabs;
      width: 100%;
    }

    &__selects {
      grid-area: selects;
    }

    &__stats-select {
      grid-area: stats;
      display: flex;
      justify-content: center;
    }
  }
</style>
